"use strict";

!(function (factory) {
  "function" == typeof define && define.amd && define.amd.jQuery
    ? define(["jquery"], factory)
    : factory(
        "undefined" != typeof module && module.exports
          ? require("jquery")
          : jQuery
      );
})(function ($) {
  "use strict";
  function init(options) {
    return (
      !options ||
        void 0 !== options.allowPageScroll ||
        (void 0 === options.swipe && void 0 === options.swipeStatus) ||
        (options.allowPageScroll = NONE),
      void 0 !== options.click &&
        void 0 === options.tap &&
        (options.tap = options.click),
      options || (options = {}),
      (options = $.extend({}, $.fn.swipe.defaults, options)),
      this.each(function () {
        var $this = $(this),
          plugin = $this.data(PLUGIN_NS);
        plugin ||
          ((plugin = new TouchSwipe(this, options)),
          $this.data(PLUGIN_NS, plugin));
      })
    );
  }
  function TouchSwipe(element, options) {
    function touchStart(jqEvent) {
      if (
        !(
          getTouchInProgress() ||
          $(jqEvent.target).closest(options.excludedElements, $element).length >
            0
        )
      ) {
        var event = jqEvent.originalEvent ? jqEvent.originalEvent : jqEvent;
        if (
          !event.pointerType ||
          "mouse" != event.pointerType ||
          0 != options.fallbackToMouseEvents
        ) {
          var ret,
            touches = event.touches,
            evt = touches ? touches[0] : event;
          return (
            (phase = PHASE_START),
            touches
              ? (fingerCount = touches.length)
              : options.preventDefaultEvents !== !1 && jqEvent.preventDefault(),
            (distance = 0),
            (direction = null),
            (currentDirection = null),
            (pinchDirection = null),
            (duration = 0),
            (startTouchesDistance = 0),
            (endTouchesDistance = 0),
            (pinchZoom = 1),
            (pinchDistance = 0),
            (maximumsMap = createMaximumsData()),
            cancelMultiFingerRelease(),
            createFingerData(0, evt),
            !touches ||
            fingerCount === options.fingers ||
            options.fingers === ALL_FINGERS ||
            hasPinches()
              ? ((startTime = getTimeStamp()),
                2 == fingerCount &&
                  (createFingerData(1, touches[1]),
                  (startTouchesDistance = endTouchesDistance = calculateTouchesDistance(
                    fingerData[0].start,
                    fingerData[1].start
                  ))),
                (options.swipeStatus || options.pinchStatus) &&
                  (ret = triggerHandler(event, phase)))
              : (ret = !1),
            ret === !1
              ? ((phase = PHASE_CANCEL), triggerHandler(event, phase), ret)
              : (options.hold &&
                  (holdTimeout = setTimeout(
                    $.proxy(function () {
                      $element.trigger("hold", [event.target]),
                        options.hold &&
                          (ret = options.hold.call(
                            $element,
                            event,
                            event.target
                          ));
                    }, this),
                    options.longTapThreshold
                  )),
                setTouchInProgress(!0),
                null)
          );
        }
      }
    }
    function touchMove(jqEvent) {
      var event = jqEvent.originalEvent ? jqEvent.originalEvent : jqEvent;
      if (
        phase !== PHASE_END &&
        phase !== PHASE_CANCEL &&
        !inMultiFingerRelease()
      ) {
        var ret,
          touches = event.touches,
          evt = touches ? touches[0] : event,
          currentFinger = updateFingerData(evt);
        if (
          ((endTime = getTimeStamp()),
          touches && (fingerCount = touches.length),
          options.hold && clearTimeout(holdTimeout),
          (phase = PHASE_MOVE),
          2 == fingerCount &&
            (0 == startTouchesDistance
              ? (createFingerData(1, touches[1]),
                (startTouchesDistance = endTouchesDistance = calculateTouchesDistance(
                  fingerData[0].start,
                  fingerData[1].start
                )))
              : (updateFingerData(touches[1]),
                (endTouchesDistance = calculateTouchesDistance(
                  fingerData[0].end,
                  fingerData[1].end
                )),
                (pinchDirection = calculatePinchDirection(
                  fingerData[0].end,
                  fingerData[1].end
                ))),
            (pinchZoom = calculatePinchZoom(
              startTouchesDistance,
              endTouchesDistance
            )),
            (pinchDistance = Math.abs(
              startTouchesDistance - endTouchesDistance
            ))),
          fingerCount === options.fingers ||
            options.fingers === ALL_FINGERS ||
            !touches ||
            hasPinches())
        ) {
          if (
            ((direction = calculateDirection(
              currentFinger.start,
              currentFinger.end
            )),
            (currentDirection = calculateDirection(
              currentFinger.last,
              currentFinger.end
            )),
            validateDefaultEvent(jqEvent, currentDirection),
            (distance = calculateDistance(
              currentFinger.start,
              currentFinger.end
            )),
            (duration = calculateDuration()),
            setMaxDistance(direction, distance),
            (ret = triggerHandler(event, phase)),
            !options.triggerOnTouchEnd || options.triggerOnTouchLeave)
          ) {
            var inBounds = !0;
            if (options.triggerOnTouchLeave) {
              var bounds = getbounds(this);
              inBounds = isInBounds(currentFinger.end, bounds);
            }
            !options.triggerOnTouchEnd && inBounds
              ? (phase = getNextPhase(PHASE_MOVE))
              : options.triggerOnTouchLeave &&
                !inBounds &&
                (phase = getNextPhase(PHASE_END)),
              (phase != PHASE_CANCEL && phase != PHASE_END) ||
                triggerHandler(event, phase);
          }
        } else (phase = PHASE_CANCEL), triggerHandler(event, phase);
        ret === !1 && ((phase = PHASE_CANCEL), triggerHandler(event, phase));
      }
    }
    function touchEnd(jqEvent) {
      var event = jqEvent.originalEvent ? jqEvent.originalEvent : jqEvent,
        touches = event.touches;
      if (touches) {
        if (touches.length && !inMultiFingerRelease())
          return startMultiFingerRelease(event), !0;
        if (touches.length && inMultiFingerRelease()) return !0;
      }
      return (
        inMultiFingerRelease() && (fingerCount = fingerCountAtRelease),
        (endTime = getTimeStamp()),
        (duration = calculateDuration()),
        didSwipeBackToCancel() || !validateSwipeDistance()
          ? ((phase = PHASE_CANCEL), triggerHandler(event, phase))
          : options.triggerOnTouchEnd ||
            (options.triggerOnTouchEnd === !1 && phase === PHASE_MOVE)
          ? (options.preventDefaultEvents !== !1 && jqEvent.preventDefault(),
            (phase = PHASE_END),
            triggerHandler(event, phase))
          : !options.triggerOnTouchEnd && hasTap()
          ? ((phase = PHASE_END), triggerHandlerForGesture(event, phase, TAP))
          : phase === PHASE_MOVE &&
            ((phase = PHASE_CANCEL), triggerHandler(event, phase)),
        setTouchInProgress(!1),
        null
      );
    }
    function touchCancel() {
      (fingerCount = 0),
        (endTime = 0),
        (startTime = 0),
        (startTouchesDistance = 0),
        (endTouchesDistance = 0),
        (pinchZoom = 1),
        cancelMultiFingerRelease(),
        setTouchInProgress(!1);
    }
    function touchLeave(jqEvent) {
      var event = jqEvent.originalEvent ? jqEvent.originalEvent : jqEvent;
      options.triggerOnTouchLeave &&
        ((phase = getNextPhase(PHASE_END)), triggerHandler(event, phase));
    }
    function removeListeners() {
      $element.unbind(START_EV, touchStart),
        $element.unbind(CANCEL_EV, touchCancel),
        $element.unbind(MOVE_EV, touchMove),
        $element.unbind(END_EV, touchEnd),
        LEAVE_EV && $element.unbind(LEAVE_EV, touchLeave),
        setTouchInProgress(!1);
    }
    function getNextPhase(currentPhase) {
      var nextPhase = currentPhase,
        validTime = validateSwipeTime(),
        validDistance = validateSwipeDistance(),
        didCancel = didSwipeBackToCancel();
      return (
        !validTime || didCancel
          ? (nextPhase = PHASE_CANCEL)
          : !validDistance ||
            currentPhase != PHASE_MOVE ||
            (options.triggerOnTouchEnd && !options.triggerOnTouchLeave)
          ? !validDistance &&
            currentPhase == PHASE_END &&
            options.triggerOnTouchLeave &&
            (nextPhase = PHASE_CANCEL)
          : (nextPhase = PHASE_END),
        nextPhase
      );
    }
    function triggerHandler(event, phase) {
      var ret,
        touches = event.touches;
      return (
        (didSwipe() || hasSwipes()) &&
          (ret = triggerHandlerForGesture(event, phase, SWIPE)),
        (didPinch() || hasPinches()) &&
          ret !== !1 &&
          (ret = triggerHandlerForGesture(event, phase, PINCH)),
        didDoubleTap() && ret !== !1
          ? (ret = triggerHandlerForGesture(event, phase, DOUBLE_TAP))
          : didLongTap() && ret !== !1
          ? (ret = triggerHandlerForGesture(event, phase, LONG_TAP))
          : didTap() &&
            ret !== !1 &&
            (ret = triggerHandlerForGesture(event, phase, TAP)),
        phase === PHASE_CANCEL && touchCancel(event),
        phase === PHASE_END &&
          (touches ? touches.length || touchCancel(event) : touchCancel(event)),
        ret
      );
    }
    function triggerHandlerForGesture(event, phase, gesture) {
      var ret;
      if (gesture == SWIPE) {
        if (
          ($element.trigger("swipeStatus", [
            phase,
            direction || null,
            distance || 0,
            duration || 0,
            fingerCount,
            fingerData,
            currentDirection,
          ]),
          options.swipeStatus &&
            ((ret = options.swipeStatus.call(
              $element,
              event,
              phase,
              direction || null,
              distance || 0,
              duration || 0,
              fingerCount,
              fingerData,
              currentDirection
            )),
            ret === !1))
        )
          return !1;
        if (phase == PHASE_END && validateSwipe()) {
          if (
            (clearTimeout(singleTapTimeout),
            clearTimeout(holdTimeout),
            $element.trigger("swipe", [
              direction,
              distance,
              duration,
              fingerCount,
              fingerData,
              currentDirection,
            ]),
            options.swipe &&
              ((ret = options.swipe.call(
                $element,
                event,
                direction,
                distance,
                duration,
                fingerCount,
                fingerData,
                currentDirection
              )),
              ret === !1))
          )
            return !1;
          switch (direction) {
            case LEFT:
              $element.trigger("swipeLeft", [
                direction,
                distance,
                duration,
                fingerCount,
                fingerData,
                currentDirection,
              ]),
                options.swipeLeft &&
                  (ret = options.swipeLeft.call(
                    $element,
                    event,
                    direction,
                    distance,
                    duration,
                    fingerCount,
                    fingerData,
                    currentDirection
                  ));
              break;
            case RIGHT:
              $element.trigger("swipeRight", [
                direction,
                distance,
                duration,
                fingerCount,
                fingerData,
                currentDirection,
              ]),
                options.swipeRight &&
                  (ret = options.swipeRight.call(
                    $element,
                    event,
                    direction,
                    distance,
                    duration,
                    fingerCount,
                    fingerData,
                    currentDirection
                  ));
              break;
            case UP:
              $element.trigger("swipeUp", [
                direction,
                distance,
                duration,
                fingerCount,
                fingerData,
                currentDirection,
              ]),
                options.swipeUp &&
                  (ret = options.swipeUp.call(
                    $element,
                    event,
                    direction,
                    distance,
                    duration,
                    fingerCount,
                    fingerData,
                    currentDirection
                  ));
              break;
            case DOWN:
              $element.trigger("swipeDown", [
                direction,
                distance,
                duration,
                fingerCount,
                fingerData,
                currentDirection,
              ]),
                options.swipeDown &&
                  (ret = options.swipeDown.call(
                    $element,
                    event,
                    direction,
                    distance,
                    duration,
                    fingerCount,
                    fingerData,
                    currentDirection
                  ));
          }
        }
      }
      if (gesture == PINCH) {
        if (
          ($element.trigger("pinchStatus", [
            phase,
            pinchDirection || null,
            pinchDistance || 0,
            duration || 0,
            fingerCount,
            pinchZoom,
            fingerData,
          ]),
          options.pinchStatus &&
            ((ret = options.pinchStatus.call(
              $element,
              event,
              phase,
              pinchDirection || null,
              pinchDistance || 0,
              duration || 0,
              fingerCount,
              pinchZoom,
              fingerData
            )),
            ret === !1))
        )
          return !1;
        if (phase == PHASE_END && validatePinch())
          switch (pinchDirection) {
            case IN:
              $element.trigger("pinchIn", [
                pinchDirection || null,
                pinchDistance || 0,
                duration || 0,
                fingerCount,
                pinchZoom,
                fingerData,
              ]),
                options.pinchIn &&
                  (ret = options.pinchIn.call(
                    $element,
                    event,
                    pinchDirection || null,
                    pinchDistance || 0,
                    duration || 0,
                    fingerCount,
                    pinchZoom,
                    fingerData
                  ));
              break;
            case OUT:
              $element.trigger("pinchOut", [
                pinchDirection || null,
                pinchDistance || 0,
                duration || 0,
                fingerCount,
                pinchZoom,
                fingerData,
              ]),
                options.pinchOut &&
                  (ret = options.pinchOut.call(
                    $element,
                    event,
                    pinchDirection || null,
                    pinchDistance || 0,
                    duration || 0,
                    fingerCount,
                    pinchZoom,
                    fingerData
                  ));
          }
      }
      return (
        gesture == TAP
          ? (phase !== PHASE_CANCEL && phase !== PHASE_END) ||
            (clearTimeout(singleTapTimeout),
            clearTimeout(holdTimeout),
            hasDoubleTap() && !inDoubleTap()
              ? ((doubleTapStartTime = getTimeStamp()),
                (singleTapTimeout = setTimeout(
                  $.proxy(function () {
                    (doubleTapStartTime = null),
                      $element.trigger("tap", [event.target]),
                      options.tap &&
                        (ret = options.tap.call($element, event, event.target));
                  }, this),
                  options.doubleTapThreshold
                )))
              : ((doubleTapStartTime = null),
                $element.trigger("tap", [event.target]),
                options.tap &&
                  (ret = options.tap.call($element, event, event.target))))
          : gesture == DOUBLE_TAP
          ? (phase !== PHASE_CANCEL && phase !== PHASE_END) ||
            (clearTimeout(singleTapTimeout),
            clearTimeout(holdTimeout),
            (doubleTapStartTime = null),
            $element.trigger("doubletap", [event.target]),
            options.doubleTap &&
              (ret = options.doubleTap.call($element, event, event.target)))
          : gesture == LONG_TAP &&
            ((phase !== PHASE_CANCEL && phase !== PHASE_END) ||
              (clearTimeout(singleTapTimeout),
              (doubleTapStartTime = null),
              $element.trigger("longtap", [event.target]),
              options.longTap &&
                (ret = options.longTap.call($element, event, event.target)))),
        ret
      );
    }
    function validateSwipeDistance() {
      var valid = !0;
      return (
        null !== options.threshold && (valid = distance >= options.threshold),
        valid
      );
    }
    function didSwipeBackToCancel() {
      var cancelled = !1;
      return (
        null !== options.cancelThreshold &&
          null !== direction &&
          (cancelled =
            getMaxDistance(direction) - distance >= options.cancelThreshold),
        cancelled
      );
    }
    function validatePinchDistance() {
      return (
        null === options.pinchThreshold ||
        pinchDistance >= options.pinchThreshold
      );
    }
    function validateSwipeTime() {
      var result;
      return (result =
        !options.maxTimeThreshold || !(duration >= options.maxTimeThreshold));
    }
    function validateDefaultEvent(jqEvent, direction) {
      if (options.preventDefaultEvents !== !1)
        if (options.allowPageScroll === NONE) jqEvent.preventDefault();
        else {
          var auto = options.allowPageScroll === AUTO;
          switch (direction) {
            case LEFT:
              ((options.swipeLeft && auto) ||
                (!auto && options.allowPageScroll != HORIZONTAL)) &&
                jqEvent.preventDefault();
              break;
            case RIGHT:
              ((options.swipeRight && auto) ||
                (!auto && options.allowPageScroll != HORIZONTAL)) &&
                jqEvent.preventDefault();
              break;
            case UP:
              ((options.swipeUp && auto) ||
                (!auto && options.allowPageScroll != VERTICAL)) &&
                jqEvent.preventDefault();
              break;
            case DOWN:
              ((options.swipeDown && auto) ||
                (!auto && options.allowPageScroll != VERTICAL)) &&
                jqEvent.preventDefault();
              break;
            case NONE:
          }
        }
    }
    function validatePinch() {
      var hasCorrectFingerCount = validateFingers(),
        hasEndPoint = validateEndPoint(),
        hasCorrectDistance = validatePinchDistance();
      return hasCorrectFingerCount && hasEndPoint && hasCorrectDistance;
    }
    function hasPinches() {
      return !!(options.pinchStatus || options.pinchIn || options.pinchOut);
    }
    function didPinch() {
      return !(!validatePinch() || !hasPinches());
    }
    function validateSwipe() {
      var hasValidTime = validateSwipeTime(),
        hasValidDistance = validateSwipeDistance(),
        hasCorrectFingerCount = validateFingers(),
        hasEndPoint = validateEndPoint(),
        didCancel = didSwipeBackToCancel(),
        valid =
          !didCancel &&
          hasEndPoint &&
          hasCorrectFingerCount &&
          hasValidDistance &&
          hasValidTime;
      return valid;
    }
    function hasSwipes() {
      return !!(
        options.swipe ||
        options.swipeStatus ||
        options.swipeLeft ||
        options.swipeRight ||
        options.swipeUp ||
        options.swipeDown
      );
    }
    function didSwipe() {
      return !(!validateSwipe() || !hasSwipes());
    }
    function validateFingers() {
      return (
        fingerCount === options.fingers ||
        options.fingers === ALL_FINGERS ||
        !SUPPORTS_TOUCH
      );
    }
    function validateEndPoint() {
      return 0 !== fingerData[0].end.x;
    }
    function hasTap() {
      return !!options.tap;
    }
    function hasDoubleTap() {
      return !!options.doubleTap;
    }
    function hasLongTap() {
      return !!options.longTap;
    }
    function validateDoubleTap() {
      if (null == doubleTapStartTime) return !1;
      var now = getTimeStamp();
      return (
        hasDoubleTap() && now - doubleTapStartTime <= options.doubleTapThreshold
      );
    }
    function inDoubleTap() {
      return validateDoubleTap();
    }
    function validateTap() {
      return (
        (1 === fingerCount || !SUPPORTS_TOUCH) &&
        (isNaN(distance) || distance < options.threshold)
      );
    }
    function validateLongTap() {
      return (
        duration > options.longTapThreshold && distance < DOUBLE_TAP_THRESHOLD
      );
    }
    function didTap() {
      return !(!validateTap() || !hasTap());
    }
    function didDoubleTap() {
      return !(!validateDoubleTap() || !hasDoubleTap());
    }
    function didLongTap() {
      return !(!validateLongTap() || !hasLongTap());
    }
    function startMultiFingerRelease(event) {
      (previousTouchEndTime = getTimeStamp()),
        (fingerCountAtRelease = event.touches.length + 1);
    }
    function cancelMultiFingerRelease() {
      (previousTouchEndTime = 0), (fingerCountAtRelease = 0);
    }
    function inMultiFingerRelease() {
      var withinThreshold = !1;
      if (previousTouchEndTime) {
        var diff = getTimeStamp() - previousTouchEndTime;
        diff <= options.fingerReleaseThreshold && (withinThreshold = !0);
      }
      return withinThreshold;
    }
    function getTouchInProgress() {
      return !($element.data(PLUGIN_NS + "_intouch") !== !0);
    }
    function setTouchInProgress(val) {
      $element &&
        (val === !0
          ? ($element.bind(MOVE_EV, touchMove),
            $element.bind(END_EV, touchEnd),
            LEAVE_EV && $element.bind(LEAVE_EV, touchLeave))
          : ($element.unbind(MOVE_EV, touchMove, !1),
            $element.unbind(END_EV, touchEnd, !1),
            LEAVE_EV && $element.unbind(LEAVE_EV, touchLeave, !1)),
        $element.data(PLUGIN_NS + "_intouch", val === !0));
    }
    function createFingerData(id, evt) {
      var f = {
        start: { x: 0, y: 0 },
        last: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
      };
      return (
        (f.start.x = f.last.x = f.end.x = evt.pageX || evt.clientX),
        (f.start.y = f.last.y = f.end.y = evt.pageY || evt.clientY),
        (fingerData[id] = f),
        f
      );
    }
    function updateFingerData(evt) {
      var id = void 0 !== evt.identifier ? evt.identifier : 0,
        f = getFingerData(id);
      return (
        null === f && (f = createFingerData(id, evt)),
        (f.last.x = f.end.x),
        (f.last.y = f.end.y),
        (f.end.x = evt.pageX || evt.clientX),
        (f.end.y = evt.pageY || evt.clientY),
        f
      );
    }
    function getFingerData(id) {
      return fingerData[id] || null;
    }
    function setMaxDistance(direction, distance) {
      direction != NONE &&
        ((distance = Math.max(distance, getMaxDistance(direction))),
        (maximumsMap[direction].distance = distance));
    }
    function getMaxDistance(direction) {
      if (maximumsMap[direction]) return maximumsMap[direction].distance;
    }
    function createMaximumsData() {
      var maxData = {};
      return (
        (maxData[LEFT] = createMaximumVO(LEFT)),
        (maxData[RIGHT] = createMaximumVO(RIGHT)),
        (maxData[UP] = createMaximumVO(UP)),
        (maxData[DOWN] = createMaximumVO(DOWN)),
        maxData
      );
    }
    function createMaximumVO(dir) {
      return { direction: dir, distance: 0 };
    }
    function calculateDuration() {
      return endTime - startTime;
    }
    function calculateTouchesDistance(startPoint, endPoint) {
      var diffX = Math.abs(startPoint.x - endPoint.x),
        diffY = Math.abs(startPoint.y - endPoint.y);
      return Math.round(Math.sqrt(diffX * diffX + diffY * diffY));
    }
    function calculatePinchZoom(startDistance, endDistance) {
      var percent = (endDistance / startDistance) * 1;
      return percent.toFixed(2);
    }
    function calculatePinchDirection() {
      return pinchZoom < 1 ? OUT : IN;
    }
    function calculateDistance(startPoint, endPoint) {
      return Math.round(
        Math.sqrt(
          Math.pow(endPoint.x - startPoint.x, 2) +
            Math.pow(endPoint.y - startPoint.y, 2)
        )
      );
    }
    function calculateAngle(startPoint, endPoint) {
      var x = startPoint.x - endPoint.x,
        y = endPoint.y - startPoint.y,
        r = Math.atan2(y, x),
        angle = Math.round((180 * r) / Math.PI);
      return angle < 0 && (angle = 360 - Math.abs(angle)), angle;
    }
    function calculateDirection(startPoint, endPoint) {
      if (comparePoints(startPoint, endPoint)) return NONE;
      var angle = calculateAngle(startPoint, endPoint);
      return angle <= 45 && angle >= 0
        ? LEFT
        : angle <= 360 && angle >= 315
        ? LEFT
        : angle >= 135 && angle <= 225
        ? RIGHT
        : angle > 45 && angle < 135
        ? DOWN
        : UP;
    }
    function getTimeStamp() {
      var now = new Date();
      return now.getTime();
    }
    function getbounds(el) {
      el = $(el);
      var offset = el.offset(),
        bounds = {
          left: offset.left,
          right: offset.left + el.outerWidth(),
          top: offset.top,
          bottom: offset.top + el.outerHeight(),
        };
      return bounds;
    }
    function isInBounds(point, bounds) {
      return (
        point.x > bounds.left &&
        point.x < bounds.right &&
        point.y > bounds.top &&
        point.y < bounds.bottom
      );
    }
    function comparePoints(pointA, pointB) {
      return pointA.x == pointB.x && pointA.y == pointB.y;
    }
    var options = $.extend({}, options),
      useTouchEvents =
        SUPPORTS_TOUCH || SUPPORTS_POINTER || !options.fallbackToMouseEvents,
      START_EV = useTouchEvents
        ? SUPPORTS_POINTER
          ? SUPPORTS_POINTER_IE10
            ? "MSPointerDown"
            : "pointerdown"
          : "touchstart"
        : "mousedown",
      MOVE_EV = useTouchEvents
        ? SUPPORTS_POINTER
          ? SUPPORTS_POINTER_IE10
            ? "MSPointerMove"
            : "pointermove"
          : "touchmove"
        : "mousemove",
      END_EV = useTouchEvents
        ? SUPPORTS_POINTER
          ? SUPPORTS_POINTER_IE10
            ? "MSPointerUp"
            : "pointerup"
          : "touchend"
        : "mouseup",
      LEAVE_EV = useTouchEvents
        ? SUPPORTS_POINTER
          ? "mouseleave"
          : null
        : "mouseleave",
      CANCEL_EV = SUPPORTS_POINTER
        ? SUPPORTS_POINTER_IE10
          ? "MSPointerCancel"
          : "pointercancel"
        : "touchcancel",
      distance = 0,
      direction = null,
      currentDirection = null,
      duration = 0,
      startTouchesDistance = 0,
      endTouchesDistance = 0,
      pinchZoom = 1,
      pinchDistance = 0,
      pinchDirection = 0,
      maximumsMap = null,
      $element = $(element),
      phase = "start",
      fingerCount = 0,
      fingerData = {},
      startTime = 0,
      endTime = 0,
      previousTouchEndTime = 0,
      fingerCountAtRelease = 0,
      doubleTapStartTime = 0,
      singleTapTimeout = null,
      holdTimeout = null;
    try {
      $element.bind(START_EV, touchStart),
        $element.bind(CANCEL_EV, touchCancel);
    } catch (e) {
      $.error(
        "events not supported " +
          START_EV +
          "," +
          CANCEL_EV +
          " on jQuery.swipe"
      );
    }
    (this.enable = function () {
      return (
        this.disable(),
        $element.bind(START_EV, touchStart),
        $element.bind(CANCEL_EV, touchCancel),
        $element
      );
    }),
      (this.disable = function () {
        return removeListeners(), $element;
      }),
      (this.destroy = function () {
        removeListeners(), $element.data(PLUGIN_NS, null), ($element = null);
      }),
      (this.option = function (property, value) {
        if ("object" == typeof property) options = $.extend(options, property);
        else if (void 0 !== options[property]) {
          if (void 0 === value) return options[property];
          options[property] = value;
        } else {
          if (!property) return options;
          $.error(
            "Option " + property + " does not exist on jQuery.swipe.options"
          );
        }
        return null;
      });
  }
  var VERSION = "1.6.18",
    LEFT = "left",
    RIGHT = "right",
    UP = "up",
    DOWN = "down",
    IN = "in",
    OUT = "out",
    NONE = "none",
    AUTO = "auto",
    SWIPE = "swipe",
    PINCH = "pinch",
    TAP = "tap",
    DOUBLE_TAP = "doubletap",
    LONG_TAP = "longtap",
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
    ALL_FINGERS = "all",
    DOUBLE_TAP_THRESHOLD = 10,
    PHASE_START = "start",
    PHASE_MOVE = "move",
    PHASE_END = "end",
    PHASE_CANCEL = "cancel",
    SUPPORTS_TOUCH = "ontouchstart" in window,
    SUPPORTS_POINTER_IE10 =
      window.navigator.msPointerEnabled &&
      !window.PointerEvent &&
      !SUPPORTS_TOUCH,
    SUPPORTS_POINTER =
      (window.PointerEvent || window.navigator.msPointerEnabled) &&
      !SUPPORTS_TOUCH,
    PLUGIN_NS = "TouchSwipe",
    defaults = {
      fingers: 1,
      threshold: 75,
      cancelThreshold: null,
      pinchThreshold: 20,
      maxTimeThreshold: null,
      fingerReleaseThreshold: 250,
      longTapThreshold: 500,
      doubleTapThreshold: 200,
      swipe: null,
      swipeLeft: null,
      swipeRight: null,
      swipeUp: null,
      swipeDown: null,
      swipeStatus: null,
      pinchIn: null,
      pinchOut: null,
      pinchStatus: null,
      click: null,
      tap: null,
      doubleTap: null,
      longTap: null,
      hold: null,
      triggerOnTouchEnd: !0,
      triggerOnTouchLeave: !1,
      allowPageScroll: "auto",
      fallbackToMouseEvents: !0,
      excludedElements: ".noSwipe",
      preventDefaultEvents: !0,
    };
  ($.fn.swipe = function (method) {
    var $this = $(this),
      plugin = $this.data(PLUGIN_NS);
    if (plugin && "string" == typeof method) {
      if (plugin[method])
        return plugin[method].apply(
          plugin,
          Array.prototype.slice.call(arguments, 1)
        );
      $.error("Method " + method + " does not exist on jQuery.swipe");
    } else if (plugin && "object" == typeof method)
      plugin.option.apply(plugin, arguments);
    else if (!(plugin || ("object" != typeof method && method)))
      return init.apply(this, arguments);
    return $this;
  }),
    ($.fn.swipe.version = VERSION),
    ($.fn.swipe.defaults = defaults),
    ($.fn.swipe.phases = {
      PHASE_START: PHASE_START,
      PHASE_MOVE: PHASE_MOVE,
      PHASE_END: PHASE_END,
      PHASE_CANCEL: PHASE_CANCEL,
    }),
    ($.fn.swipe.directions = {
      LEFT: LEFT,
      RIGHT: RIGHT,
      UP: UP,
      DOWN: DOWN,
      IN: IN,
      OUT: OUT,
    }),
    ($.fn.swipe.pageScroll = {
      NONE: NONE,
      HORIZONTAL: HORIZONTAL,
      VERTICAL: VERTICAL,
      AUTO: AUTO,
    }),
    ($.fn.swipe.fingers = {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5,
      ALL: ALL_FINGERS,
    });
});

//hidding menu elements that do not fit in menu width
function menuHideExtraElements() {
  jQuery("#more-li").remove();
  var wrapperWidth = jQuery(".sf-menu").width();
  var summaryWidth = 0;
  //get all first level menu items
  var $liElements = jQuery(".sf-menu > li");
  $liElements.removeClass("md-hidden");
  $liElements.each(function (index) {
    var elementWidth = jQuery(this).outerWidth();
    summaryWidth += elementWidth;
    if (summaryWidth >= wrapperWidth) {
      var $newLi = jQuery('<li id="more-li"><a>...</a><ul></ul></li>');
      jQuery($liElements[index - 1]).before($newLi);
      var newLiWidth = jQuery($newLi).outerWidth(true);
      var $extraLiElements = $liElements.filter(":gt(" + (index - 2) + ")");
      $extraLiElements.clone().appendTo($newLi.find("ul"));
      $extraLiElements.addClass("md-hidden");
      return false;
    }
  });
}

function initMegaMenu() {
  var $megaMenu = jQuery(".mainmenu_wrapper .mega-menu");
  if ($megaMenu.length) {
    var windowWidth = jQuery("body").innerWidth();
    if (windowWidth > 991) {
      $megaMenu.each(function () {
        var $thisMegaMenu = jQuery(this);
        //temporary showing mega menu to propper size calc
        $thisMegaMenu.css({ display: "block", left: "auto" });
        var thisWidth = $thisMegaMenu.outerWidth();
        var thisOffset = $thisMegaMenu.offset().left;
        var thisLeft = thisOffset + thisWidth / 2 - windowWidth / 2;
        $thisMegaMenu.css({ left: -thisLeft, display: "none" });
        if (!$thisMegaMenu.closest("ul").hasClass("nav")) {
          $thisMegaMenu.css("left", "");
        }
      });
    }
  }
}

function affixSidebarInit() {
  var $affixAside = jQuery(".affix-aside");
  if ($affixAside.length) {
    //on stick and unstick event
    $affixAside
      .on("affix.bs.affix", function (e) {
        var affixWidth = $affixAside.width() - 1;
        var affixLeft = $affixAside.offset().left;
        $affixAside.width(affixWidth).css("left", affixLeft);
      })
      .on("affix-top.bs.affix affix-bottom.bs.affix", function (e) {
        $affixAside.css({ width: "", left: "" });
      });

    //counting offset
    var offsetTop = $affixAside.offset().top - jQuery(".page_header").height();
    var offsetBottom =
      jQuery(".page_footer").outerHeight(true) +
      jQuery(".page_copyright").outerHeight(true);

    jQuery(window).on("resize", function () {
      $affixAside.css({ width: "", left: "" });

      if ($affixAside.hasClass("affix")) {
        //returning sidebar in top position if it is sticked because of unexpacted behavior
        $affixAside.removeClass("affix").css("left", "").addClass("affix-top");
      }

      var offsetTop =
        jQuery(".page_topline").outerHeight(true) +
        jQuery(".page_toplogo").outerHeight(true) +
        jQuery(".page_breadcrumbs").outerHeight(true) +
        jQuery(".page_header").outerHeight(true);
      var offsetBottom =
        jQuery(".page_footer").outerHeight(true) +
        jQuery(".page_copyright").outerHeight(true);

      $affixAside.data("bs.affix").options.offset.top = offsetTop;
      $affixAside.data("bs.affix").options.offset.bottom = offsetBottom;
    });
  } //eof checking of affix sidebar existing
}

//function that initiating template plugins on document.ready event
function documentReadyInit() {
  ////////////
  //mainmenu//
  ////////////
  if (jQuery().scrollbar) {
    jQuery('[class*="scrollbar-"]').scrollbar();
  }
  if (jQuery().superfish) {
    jQuery("ul.sf-menu").superfish({
      popUpSelector: "ul:not(.mega-menu ul), .mega-menu ",
      delay: 700,
      animation: { opacity: "show", marginTop: 1 },
      animationOut: { opacity: "hide", marginTop: 5 },
      speed: 200,
      speedOut: 200,
      disableHI: false,
      cssArrows: true,
      autoArrows: true,
    });
    jQuery("ul.sf-menu-side").superfish({
      popUpSelector: "ul:not(.mega-menu ul), .mega-menu ",
      delay: 500,
      animation: { opacity: "show", height: 100 + "%" },
      animationOut: { opacity: "hide", height: 0 },
      speed: 400,
      speedOut: 300,
      disableHI: false,
      cssArrows: true,
      autoArrows: true,
    });
  }

  //toggle mobile menu
  jQuery(".toggle_menu").on("click", function () {
    jQuery(".toggle_menu").toggleClass("mobile-active");
    jQuery(".page_header").toggleClass("mobile-active");
  });

  jQuery(".mainmenu a").on("click", function () {
    if (!jQuery(this).hasClass("sf-with-ul")) {
      jQuery(".toggle_menu").toggleClass("mobile-active");
      jQuery(".page_header").toggleClass("mobile-active");
    }
  });

  //side header processing
  var $sideHeader = jQuery(".page_header_side");
  if ($sideHeader.length) {
    var $body = jQuery("body");
    jQuery(".toggle_menu_side").on("click", function () {
      if (jQuery(this).hasClass("header-slide")) {
        $sideHeader.toggleClass("active-slide-side-header");
      } else {
        if (jQuery(this).parent().hasClass("header_side_right")) {
          $body.toggleClass("active-side-header slide-right");
        } else {
          $body.toggleClass("active-side-header");
        }
      }
    });
    // toggle sub-menus visibility on menu-side-click
    jQuery("ul.menu-side-click")
      .find("li")
      .each(function () {
        var $thisLi = jQuery(this);
        //toggle submenu only for menu items with submenu
        if ($thisLi.find("ul").length) {
          $thisLi
            .append('<span class="activate_submenu"></span>')
            .find(".activate_submenu")
            .on("click", function () {
              var $thisSpan = jQuery(this);
              if ($thisSpan.parent().hasClass("active-submenu")) {
                $thisSpan.parent().removeClass("active-submenu");
                return;
              }
              $thisLi
                .addClass("active-submenu")
                .siblings()
                .removeClass("active-submenu");
            });
        } //eof sumbenu check
      });
    //hidding side header on click outside header
    jQuery("body").on("click", function (e) {
      if (!jQuery(e.target).closest(".page_header_side").length) {
        $sideHeader.removeClass("active-slide-side-header");
        $body.removeClass("active-side-header slide-right");
      }
    });
  } //sideHeader check

  //1 and 2/3/4th level mainmenu offscreen fix
  var MainWindowWidth = jQuery(window).width();
  jQuery(window).on("resize", function () {
    MainWindowWidth = jQuery(window).width();
  });
  //2/3/4 levels
  jQuery(".mainmenu_wrapper .sf-menu")
    .on("mouseover", "ul li", function () {
      // jQuery('.mainmenu').on('mouseover', 'ul li', function(){
      if (MainWindowWidth > 991) {
        var $this = jQuery(this);
        // checks if third level menu exist
        var subMenuExist = $this.find("ul").length;
        if (subMenuExist > 0) {
          var subMenuWidth = $this.find("ul, div").first().width();
          var subMenuOffset =
            $this.find("ul, div").first().parent().offset().left + subMenuWidth;
          // if sub menu is off screen, give new position
          if (subMenuOffset + subMenuWidth > MainWindowWidth) {
            var newSubMenuPosition = subMenuWidth + 0;
            $this.find("ul, div").first().css({
              left: -newSubMenuPosition,
            });
          } else {
            $this.find("ul, div").first().css({
              left: "100%",
            });
          }
        }
      }
      //1st level
    })
    .on("mouseover", "> li", function () {
      if (MainWindowWidth > 991) {
        var $this = jQuery(this);
        var subMenuExist = $this.find("ul").length;
        if (subMenuExist > 0) {
          var subMenuWidth = $this.find("ul").width();
          var subMenuOffset = $this.find("ul").parent().offset().left;
          // if sub menu is off screen, give new position
          if (subMenuOffset + subMenuWidth > MainWindowWidth) {
            var newSubMenuPosition =
              MainWindowWidth - (subMenuOffset + subMenuWidth);
            $this.find("ul").first().css({
              left: newSubMenuPosition,
            });
          }
        }
      }
    });

  /////////////////////////////////////////
  //single page localscroll and scrollspy//
  /////////////////////////////////////////
  var navHeight = jQuery(".page_header").outerHeight(true);
  if (jQuery(".mainmenu_wrapper").length) {
    jQuery("body").scrollspy({
      target: ".mainmenu_wrapper",
      offset: navHeight,
    });
  }
  if (jQuery(".mainmenu_side_wrapper").length) {
    jQuery("body").scrollspy({
      target: ".mainmenu_side_wrapper",
      offset: navHeight,
    });
  }
  if (jQuery().localScroll) {
    jQuery(
      ".mainmenu_wrapper > ul, .mainmenu_side_wrapper > ul, #land"
    ).localScroll({
      duration: 900,
      easing: "easeInOutQuart",
      offset: -navHeight + 10,
    });
  }

  //toTop
  if (jQuery().UItoTop) {
    jQuery().UItoTop({ easingType: "easeOutQuart" });
  }

  //parallax
  if (jQuery().parallax) {
    jQuery(".parallax").parallax("50%", 0.01);
  }

  //prettyPhoto
  if (jQuery().prettyPhoto) {
    jQuery("a[data-gal^='prettyPhoto']").prettyPhoto({
      hook: "data-gal",
      theme:
        "facebook" /* light_rounded / dark_rounded / light_square / dark_square / facebook / pp_default*/,
    });
  }

  ////////////////////////////////////////
  //init Twitter Bootstrap JS components//
  ////////////////////////////////////////
  //bootstrap carousel
  if (jQuery().carousel) {
    jQuery(".carousel").carousel();

    $(".carousel").swipe({
      swipe: function (
        event,
        direction,
        distance,
        duration,
        fingerCount,
        fingerData
      ) {
        if (direction == "left") {
          $(this).carousel("next");
        } else if (direction == "right") {
          $(this).carousel("prev");
        }
      },
    });
  }
  //bootstrap tab - show first tab
  jQuery(".nav-tabs").each(function () {
    jQuery(this).find("a").first().tab("show");
  });
  jQuery(".tab-content").each(function () {
    jQuery(this).find(".tab-pane").first().addClass("fade in");
  });
  //bootstrap collapse - show first tab
  jQuery(".panel-group").each(function () {
    jQuery(this).find("a").first().filter(".collapsed").trigger("click");
  });
  //tooltip
  if (jQuery().tooltip) {
    jQuery('[data-toggle="tooltip"]').tooltip();
  }

  ////////////////
  //owl carousel//
  ////////////////
  if (jQuery().owlCarousel) {
    jQuery(".owl-carousel").each(function () {
      var $carousel = jQuery(this);
      var loop = $carousel.data("loop") ? $carousel.data("loop") : false;
      var margin =
        $carousel.data("margin") || $carousel.data("margin") == 0
          ? $carousel.data("margin")
          : 30;
      var nav = $carousel.data("nav") ? $carousel.data("nav") : false;
      var dots = $carousel.data("dots") ? $carousel.data("dots") : false;
      var themeClass = $carousel.data("themeclass")
        ? $carousel.data("themeclass")
        : "owl-theme";
      var center = $carousel.data("center") ? $carousel.data("center") : false;
      var items = $carousel.data("items") ? $carousel.data("items") : 4;
      var autoplay = $carousel.data("autoplay")
        ? $carousel.data("autoplay")
        : false;
      var responsiveXs = $carousel.data("responsive-xs")
        ? $carousel.data("responsive-xs")
        : 1;
      var responsiveSm = $carousel.data("responsive-sm")
        ? $carousel.data("responsive-sm")
        : 2;
      var responsiveMd = $carousel.data("responsive-md")
        ? $carousel.data("responsive-md")
        : 3;
      var responsiveLg = $carousel.data("responsive-lg")
        ? $carousel.data("responsive-lg")
        : 4;
      var filters = $carousel.data("filters")
        ? $carousel.data("filters")
        : false;

      if (filters) {
        $carousel
          .clone()
          .appendTo($carousel.parent())
          .addClass(filters.substring(1) + "-carousel-original");
        jQuery(filters).on("click", "a", function (e) {
          //processing filter link
          e.preventDefault();
          if (jQuery(this).hasClass("selected")) {
            return;
          }
          var filterValue = jQuery(this).attr("data-filter");
          jQuery(this).siblings().removeClass("selected active");
          jQuery(this).addClass("selected active");

          //removing old items
          $carousel.find(".owl-item").length;
          for (var i = $carousel.find(".owl-item").length - 1; i >= 0; i--) {
            $carousel.trigger("remove.owl.carousel", [1]);
          }

          //adding new items
          var $filteredItems = jQuery(
            $carousel
              .next()
              .find(" > " + filterValue)
              .clone()
          );
          $filteredItems.each(function () {
            $carousel.trigger("add.owl.carousel", jQuery(this));
          });

          $carousel.trigger("refresh.owl.carousel");
        });
      } //filters

      $carousel
        .owlCarousel({
          loop: loop,
          margin: margin,
          nav: nav,
          autoplay: autoplay,
          dots: dots,
          themeClass: themeClass,
          center: center,
          items: items,
          responsive: {
            0: {
              items: responsiveXs,
            },
            767: {
              items: responsiveSm,
            },
            992: {
              items: responsiveMd,
            },
            1200: {
              items: responsiveLg,
            },
          },
        })
        .addClass(themeClass);
      if (center) {
        $carousel.addClass("owl-center");
      }
    });
  } //eof owl-carousel

  /////////////////////////////////////////////////
  //PHP widgets - contact form, search, MailChimp//
  /////////////////////////////////////////////////

  //contact form processing
  jQuery("form.contact-form").on("submit", function (e) {
    e.preventDefault();
    var $form = jQuery(this);
    jQuery($form).find("span.contact-form-respond").remove();

    //checking on empty values
    jQuery($form)
      .find('[aria-required="true"], [required]')
      .each(function (index) {
        if (
          !jQuery.trim(jQuery(this).val()).length ||
          (this.type === "email" && jQuery(this).val().indexOf("@") === -1)
        ) {
          jQuery(this).parent().addClass("has-error");
          jQuery(this).on("focus", function () {
            jQuery(this).parent().removeClass("has-error");
          });
        }
      });
    //if one of form fields is empty - exit
    if ($form.find(".has-error").length) {
      return;
    }

    jQuery(".overlay").show();

    //sending form data to PHP server if fields are not empty
    var request = $form.serialize();

    grecaptcha
      .execute("6LccNe8aAAAAAC2CNc1fRTFkL13M1EzuvxS409pv", {
        action: "contact_form",
      })
      .then(function (token) {
        request = request + "&token=" + token;

        var ajax = jQuery
          .post("contact-form.php", request)
          .always(function (data) {
            jQuery(".overlay").hide();
            jQuery($form)
              .find('[type="submit"]')
              .attr("disabled", false)
              .parent()
              .append(
                '<span class="contact-form-respond highlight">' +
                  data.message +
                  "</span>"
              );
            //cleaning form
            var $formErrors = $form.find(".form-errors");
            if (!$formErrors.length) {
              $form[0].reset();
            }
          });
      });
  });

  //contact form processing
  jQuery("#newsletter").on("submit", function (e) {
    e.preventDefault();
    jQuery("#email-error").hide();

    var email = jQuery("#email").val();

    if (!email) {
      jQuery("#email-error").show();
      return;
    }

    var $form = jQuery(this).find("form")[0];

    jQuery($form).find("input,button").prop("disabled", true);

    jQuery($form).find(".fa.fa-spinner").show();

    jQuery
      .post("mailing-list.php", "email=" + email)
      .done(function () {
        jQuery("#newsletter").modal("hide");
      })
      .fail(function () {
        jQuery("#email-error").show();
      })
      .always(function () {
        jQuery($form).trigger("reset");
        jQuery($form).find("input,button").prop("disabled", false);
        jQuery($form).find(".fa.fa-spinner").hide();
      });
  });

  //MailChimp subscribe form processing
  jQuery(".signup").on("submit", function (e) {
    e.preventDefault();
    var $form = jQuery(this);
    // update user interface
    $form.find(".response").html("Adding email address...");
    // Prepare query string and send AJAX request
    jQuery.ajax({
      url: "mailchimp/store-address.php",
      data: "ajax=true&email=" + escape($form.find(".mailchimp_email").val()),
      success: function (msg) {
        $form.find(".response").html(msg);
      },
    });
  });
} //eof documentReadyInit

//function that initiating template plugins on window.load event
function windowLoadInit() {
  //flexslider
  if (jQuery().flexslider) {
    var $introSlider = jQuery(".intro_section .flexslider");
    $introSlider.each(function (index) {
      var $currentSlider = jQuery(this);
      $currentSlider
        .flexslider({
          animation: "fade",
          pauseOnHover: true,
          useCSS: true,
          controlNav: true,
          directionNav: true,
          prevText: "",
          nextText: "",
          smoothHeight: false,
          slideshowSpeed: 10000,
          animationSpeed: 600,
          start: function (slider) {
            slider
              .find(".slide_description")
              .children()
              .css({ visibility: "hidden" });
            slider
              .find(".flex-active-slide .slide_description")
              .children()
              .each(function (index) {
                var self = jQuery(this);
                var animationClass = !self.data("animation")
                  ? "fadeInRight"
                  : self.data("animation");
                setTimeout(function () {
                  self.addClass("animated " + animationClass);
                }, index * 200);
              });
          },
          after: function (slider) {
            slider
              .find(".flex-active-slide .slide_description")
              .children()
              .each(function (index) {
                var self = jQuery(this);
                var animationClass = !self.data("animation")
                  ? "fadeInRight"
                  : self.data("animation");
                setTimeout(function () {
                  self.addClass("animated " + animationClass);
                }, index * 200);
              });
          },
          end: function (slider) {
            slider
              .find(".slide_description")
              .children()
              .each(function () {
                var self = jQuery(this);
                var animationClass = !self.data("animation")
                  ? "fadeInRight"
                  : self.data("animation");
                self
                  .removeClass("animated " + animationClass)
                  .css({ visibility: "hidden" });
                // jQuery(this).attr('class', '');
              });
          },
        })
        //wrapping nav with container
        .find(".flex-control-nav")
        .wrap('<div class="container nav-container"/>');
    }); //intro_section flex slider

    jQuery(".flexslider").each(function (index) {
      var $currentSlider = jQuery(this);
      //exit if intro slider already activated
      if ($currentSlider.find(".flex-active-slide").length) {
        return;
      }
      $currentSlider.flexslider({
        animation: "fade",
        useCSS: true,
        controlNav: true,
        directionNav: false,
        prevText: "",
        nextText: "",
        smoothHeight: false,
        slideshowSpeed: 5000,
        animationSpeed: 800,
      });
    });
  }

  ////////////////////
  //header processing/
  ////////////////////
  //stick header to top
  //wrap header with div for smooth sticking
  var $header = jQuery(".page_header").first();
  if ($header.length) {
    menuHideExtraElements();
    var headerHeight = $header.outerHeight();
    $header
      .wrap('<div class="page_header_wrapper"></div>')
      .parent()
      .css({ height: headerHeight }); //wrap header for smooth stick and unstick

    //get offset
    var headerOffset = 0;
    //check for sticked template headers
    headerOffset = $header.offset().top;

    //for boxed layout - show or hide main menu elements if width has been changed on affix
    jQuery($header).on("affixed-top.bs.affix affixed.bs.affix", function (e) {
      if ($header.hasClass("affix-top")) {
        $header
          .parent()
          .removeClass("affix-wrapper affix-bottom-wrapper")
          .addClass("affix-top-wrapper");
      } else if ($header.hasClass("affix")) {
        $header
          .parent()
          .removeClass("affix-top-wrapper affix-bottom-wrapper")
          .addClass("affix-wrapper");
      } else if ($header.hasClass("affix-bottom")) {
        $header
          .parent()
          .removeClass("affix-wrapper affix-top-wrapper")
          .addClass("affix-bottom-wrapper");
      } else {
        $header
          .parent()
          .removeClass("affix-wrapper affix-top-wrapper affix-bottom-wrapper");
      }
      menuHideExtraElements();
    });

    //if header has different height on afixed and affixed-top positions - correcting wrapper height
    jQuery($header).on("affixed-top.bs.affix", function () {
      $header.parent().css({ height: $header.outerHeight() });
    });
  }

  //mega menu
  initMegaMenu();

  //aside affix
  affixSidebarInit();

  jQuery("body").scrollspy("refresh");

  //animation to elements on scroll
  if (jQuery().appear) {
    jQuery(".to_animate").appear();
    jQuery(".to_animate")
      .filter(":appeared")
      .each(function (index) {
        var self = jQuery(this);
        var animationClass = !self.data("animation")
          ? "fadeInUp"
          : self.data("animation");
        var animationDelay = !self.data("delay") ? 210 : self.data("delay");
        setTimeout(function () {
          self.addClass("animated " + animationClass);
        }, index * animationDelay);
      });

    jQuery("body").on("appear", ".to_animate", function (e, $affected) {
      jQuery($affected).each(function (index) {
        var self = jQuery(this);
        var animationClass = !self.data("animation")
          ? "fadeInUp"
          : self.data("animation");
        var animationDelay = !self.data("delay") ? 210 : self.data("delay");
        setTimeout(function () {
          self.addClass("animated " + animationClass);
        }, index * animationDelay);
      });
    });

    //counters init on scroll
    jQuery(".counter").appear();
    jQuery(".counter")
      .filter(":appeared")
      .each(function (index) {
        if (jQuery(this).hasClass("counted")) {
          return;
        } else {
          jQuery(this).countTo().addClass("counted");
        }
      });
    jQuery("body").on("appear", ".counter", function (e, $affected) {
      jQuery($affected).each(function (index) {
        if (jQuery(this).hasClass("counted")) {
          return;
        } else {
          jQuery(this).countTo().addClass("counted");
        }
      });
    });
  } //appear check
} //eof windowLoadInit

jQuery(document).ready(function () {
  documentReadyInit();
}); //end of "document ready" event

jQuery(window).on("load", function () {
  windowLoadInit();
}); //end of "window load" event

jQuery(window).on("resize", function () {
  jQuery("body").scrollspy("refresh");

  //header processing
  menuHideExtraElements();
  initMegaMenu();
  var $header = jQuery(".page_header").first();
  //checking document scrolling position
  if (
    $header.length &&
    !jQuery(document).scrollTop() &&
    $header.first().data("bs.affix")
  ) {
    $header.first().data("bs.affix").options.offset.top = $header.offset().top;
  }
  jQuery(".page_header_wrapper").css({ height: $header.first().outerHeight() }); //editing header wrapper height for smooth stick and unstick
});

jQuery(function () {
  var hash = window.location.hash;
  hash && jQuery('ul.nav a[href="' + hash + '"]').tab("show");
  setTimeout(function () {
    window.scrollTo(0, 0);
  });
});
